<template>
  <div class="w-100 clearfix">
    <div class="w-100" v-if="state==='loading'">
      <div-loader />
    </div>
    <div class="w-100" v-else-if="state==='error'">
      <error-div :message="message" />
    </div>
    <div class="w-100 d-print-none" v-else>
      <div class="mb-2 col-12 shadow-sm bg-white rounded-lg py-3">
        <div class="form-row">
          <div class="col">
            <b>{{detail.order.referenceNumber}}</b>
            <div class="w-100"></div>
            <span>{{detail.order.date | moment('dd, DD.MMM.YYYY')}}</span>
          </div>
          <div class="col-auto my-auto">
            <b class="textColor">{{$helper.convertToCurrency(detail.order.amount)}}</b>
          </div>
          <div class="col-auto my-auto" v-if="detail.order.status!=='0'">
            <b-button size="sm" variant="yellow" @click="loadPdf"
            :disabled="pdfLoading">
              <b-spinner v-if="pdfLoading" small class="mr-2 mt-1"></b-spinner>
              {{$t('print')}}
            </b-button>
            <a class="btn btn-success btn-sm ml-2"
            v-if="detail.order.retryPayment"
            :href="detail.order.paymentLink">{{$t('payNow')}}</a>
          </div>
        </div>
        <div class="form-row" v-if="detail.order.status==='0'">
          <div class="col-12 mt-3">
            <b-alert show class="m-0" variant="danger">{{detail.order.archiveNote}}</b-alert>
          </div>
        </div>
        <div class="form-row mt-2 border-top" v-if="detail.order.type===3">
          <div class="col py-2">
            <b>{{$t("delivery")}}</b>
            <div class="w-100"></div>
            {{detail.order.delivery.firstName}} {{detail.order.delivery.lastName}}
            <div class="w-100"></div>
            {{detail.order.delivery.street}}
            <div class="w-100"></div>
            {{detail.order.delivery.postalCode}} {{detail.order.delivery.city}}
            <div class="w-100"></div>
            {{detail.order.delivery.country}}
          </div>

          <div class="col-auto py-2 border-left pl-3"
               v-if="detail.order.delivery.trackingNumber!=='' && detail.order.status!=='0'">
            <b>{{$t("tracking")}}</b>
            <div class="w-100"></div>
            {{detail.order.delivery.trackingNumber}}
            <div class="w-100"></div>
            {{detail.order.delivery.carrierName}}
            <div class="w-100"></div>
            <b class="text-danger">{{$helper.convertToCurrency(detail.order.delivery.price)}}</b>
          </div>

        </div>
      </div>

      <div class="mb-3 col-12 shadow-sm bg-white rounded-lg py-3" v-if="detail.shop.length>0">
        <div class="w-100 pb-2 border-bottom">
          <b>{{$t("items")}}</b>
        </div>
        <div class="form-row" v-for="(item,index) in detail.shop" :key="'itemsRow'+index">
          <div class="col py-2">
            {{item.name}}
          </div>
          <div class="col-auto py-2">
            {{item.qty}} x {{$helper.convertToCurrency(item.rate)}}
          </div>
        </div>
      </div>


      <div class="mb-3 col-12 shadow-sm bg-white rounded-lg py-3" v-if="detail.tickets.length>0">
        <div class="w-100 pb-2 border-bottom">
          <b>{{$t("tickets")}}</b>
        </div>
        <div class="form-row" v-for="(ticket,index) in detail.tickets" :key="'ticketRow'+index">
          <div class="col py-2">
            {{ticket.ticketName}}
            <span class="text-info" v-if="ticket.amount>0">
              ({{$helper.convertToCurrency(ticket.amount)}})
            </span>
            <div class="w-100"></div>
          </div>
          <div class="col-auto py-2">
            {{ticket.date | moment('dd, DD.MMM  .YY')}}
          </div>
        </div>
      </div>



      <div class="mb-3 col-12 shadow-sm bg-white rounded-lg py-3" v-if="detail.rooms.length>0">
        <div class="w-100 pb-2 border-bottom">
          <b>{{$t("rooms")}}</b>
        </div>
        <div class="form-row" v-for="(room,index) in detail.rooms" :key="'roomsRow'+index"
        :class="(room.status==='0') ? 'expiredBg' : ''">
          <div class="col py-2">
            {{room.roomNumber}}
            <span class="text-info">({{room.slot}})</span>
            <div class="w-100"></div>
            <b class="textColor">{{room.packageName}}</b>
          </div>
          <div class="col-auto py-2">
            {{room.date | moment('dd, DD.MMM  .YY')}}
          </div>
          <div class="col-12" v-if="room.status==='0'">
            <b class="text-danger">{{$t('archived')}}</b>
          </div>
        </div>
        <div class="w-100 rounded-lg border" v-if="detail.roomArticles.length>0">
          <table class="m-0 table">
            <tbody>
            <tr v-for="(article,index) in detail.roomArticles" :key="'ticketRoomArticleRow'+index">
              <td>{{article.name}}</td>
              <td class="text-right">{{$helper.convertToCurrency(article.total)}}</td>
            </tr>
            </tbody>
          </table>
        </div>


      </div>


      <div class="mb-3 col-12 shadow-sm bg-white rounded-lg py-3" v-if="detail.guests.length>0">
        <div class="w-100 pb-2 border-bottom">
          <b>{{$t("guests")}}</b>
        </div>
        <div class="form-row" v-for="(guest,index) in detail.guests" :key="'guestsRow'+index">
          <div class="col py-2">
            {{guest.firstName}} {{guest.lastName}}
          </div>
          <div class="col-auto py-2">
            {{guest.dob | moment('dd, DD.MMM  .YY')}}
          </div>
        </div>
      </div>


      <div class="mb-3 col-12 shadow-sm bg-white rounded-lg py-3" v-if="detail.vouchers.length>0">
        <div class="w-100 pb-2 border-bottom">
          <b>{{$t("vouchers")}}</b>
        </div>
        <div class="form-row"
             :class="(voucher.isExpired) ? 'expiredBg' : ''"
             v-for="(voucher,index) in detail.vouchers" :key="'paymentRows'+index">
          <div class="col-12 col-md py-2">
            {{voucher.number}} ({{voucher.gifterName}})
            <div class="w-100"></div>
            {{voucher.receiverName}}
            <div class="w-100"></div>
            {{voucher.receiverEmail}}
          </div>
          <div class="col-12 col-md-auto py-2 text-right">
            <b>{{$helper.convertToCurrency(voucher.amount)}}</b>
            <span v-if="voucher.usedAmount>0">
              ({{$helper.convertToCurrency(voucher.usedAmount)}})
            </span>
            <div class="w-100" v-if="voucher.isExpired">
              {{$t('expired')}} {{voucher.expiryDate | moment('dd, DD.MMM.YYYY')}}
            </div>
            <div v-else class="w-100">
              {{$t('expiry')}} : {{voucher.expiryDate | moment('dd, DD.MMM.YYYY')}}
            </div>
            <div></div>
            <a class="cursor" @click="printVoucher(voucher)">{{$t('print')}}</a>
          </div>
        </div>
      </div>
      <div class="mb-3 col-12 shadow-sm bg-white rounded-lg py-3" v-if="detail.payments.length>0">
        <div class="w-100 pb-2 border-bottom">
          <b>{{$t("payments")}}</b>
        </div>
        <div class="form-row" v-for="(payment,index) in detail.payments" :key="'paymentRows'+index">
          <div class="col py-2">
            {{payment.date | moment('dd, DD.MMM.YY')}}
          </div>
          <div class="col-auto py-2">
            {{payment.card}}
            {{payment.cardNumber}}
            {{$helper.convertToCurrency(payment.amount)}}
          </div>
        </div>
      </div>
    </div>

    <print-voucher v-if="selectedVoucher!==null" :voucher="selectedVoucher"></print-voucher>
  </div>
</template>

<script>
import DivLoader from "@/components/Template/DivLoader";
import ErrorDiv from "@/components/Template/ErrorDiv";
import PrintVoucher from "@/components/Profile/Orders/PrintVoucher";
export default {
  name: "OrderDetail",
  components: {PrintVoucher, ErrorDiv, DivLoader},
  created() {
    this.getOrderDetail();
  },
  methods : {
    printVoucher(voucher) {
      this.$helper.showLoader()
      this.$http.get("/content/printVoucher", {
        responseType: 'arraybuffer',
        params : {
          voucherId : voucher.id
        }
      }).then(response => {
        this.$helper.hideLoader()
        let blob = new Blob([response.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob)
        this.$helper.hideLoader()
        window.open(url) // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions
      }).catch((error)=>{
        this.$helper.showError(error.toString());
      })
    },
    loadPdf() {
      this.pdfLoading = true;
      const id = this.$route.params.code;
      this.$http.get("/content/printOrder/"+id+"?type=customer", {
        responseType: 'arraybuffer',
        params : {
          date : this.date
        }
      }).then(response => {
        let blob = new Blob([response.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob)
        window.open(url) // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions
      }).catch((error)=>{
        alert(error.toString());
      }).finally(()=>{
        this.pdfLoading = false;
      });
    },
    getOrderDetail() {
      const id = this.$route.params.code;
      this.state = "loading";
      this.message = "";
      this.$http.get("profile/order/"+id).then(response=>{
        if(response.data.error===undefined) {
          this.state = "error";
          this.message = this.$t("unableToFindOrderDetails")
        } else if(response.data.error) {
          this.state = "error";
          this.message = response.data.message;
        } else {
          this.state = "success";
          this.detail = response.data.detail;
        }
      }).catch(()=>{
        this.state = "error";
        this.message = this.$t("unableToFindOrderDetails")
      });
    }
  },
  data() {
    return {
      pdfLoading : false,
      state : "loading",
      message : "",
      detail : {},
      selectedVoucher : null
    }
  }
}
</script>

<style scoped>

</style>
