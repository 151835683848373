<template>
  <div style="width: 100%; margin: 0 auto; box-sizing: border-box;background-color: #423026;"
  class="d-none d-print-block">
    <div style="width: 100%; background-size: cover; box-sizing: border-box;">
      <img src="./../../../assets/voucher/ticketHeader.jpg" />
    </div>
    <div style="width: 300px;margin: -40px auto;
      background-size: cover;box-sizing: border-box;">
      <img src="./../../../assets/voucher/gutschein.png" />
    </div>
    <div style="width: 100%; box-sizing: border-box; padding: 30px 10px; margin-top: 10px;">
      <table style="table-layout: fixed; width: 100%" cellpadding="10">
        <tr>
          <td>
            <label>Im Wert Von</label>
            <div class="textBlock" >{{$helper.convertToCurrency(voucher.amount)}}</div>
          </td>
          <td>
            <label>Code</label>
            <div class="textBlock">{{ voucher.number }}</div>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="color: #FFFFFF; font-size: 20px;">
            Gutschein kann nicht in Teilbeträgen oder Bargeld eingelöst werden. <br>
            Bei Verlust übernimmt Flipped Funpark keine Haftung.
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label >Empfänger Name</label>
            <div class="textBlock">{{ voucher.receiverName }}</div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label >Geschenkgeber Name</label>
            <div class="textBlock">{{ voucher.gifterName }}</div>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <label >Nachricht</label>
            <div class="textBlock">{{ voucher.message }}</div>
          </td>
        </tr>
        <tr><td></td></tr>
        <tr><td></td></tr>
        <tr>
          <td>
            <div style="height: 61px; width: 150px">
              <img src="./../../../assets/voucher/logoNobg.png" />
            </div>
          </td>
          <td style="text-align: right; color: #FFFFFF">
            <small>Robert-Bosch Straße 5 | 78224 Singen</small>
            <br />
            <small>E-Mail: <a style="color: #FFFFFF; text-decoration: none;" href="mailto:info@flipped-funpark.de">info@flipped-funpark.de</a></small>
            <br />
            <small>
              <a style="color: #FFFFFF;text-decoration: none;" href="https://www.flipped-funpark.de/">www.flipped-funpark.de</a>
            </small>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrintVoucher",
  props : {
    voucher : Object
  }
}
</script>

<style scoped>
label {
  text-transform: uppercase;
  color: #b28d2f;
  display: block;
  padding: 10px 0px 10px 0;
  font-size: 17px;
}
.textBlock {
  padding: 10px 10px 10px 10px;
  display: block;
  font-size: 17px;
  border-radius: 1rem;
  background-color: #d0c6c4;
  color : #000000;
  box-sizing: border-box;
}

</style>
